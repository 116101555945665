(()=> {
	const btn    = $('.btn[data-toggle="search"]');
	const search = $('.mobile__search');
	const input  = search.find('.form-control');
	const active = 'mobile__search--shown';

	btn.click(function(e) {
		e.preventDefault();

		search.addClass(active);
		input.focus();

		backdrop(function() {
			search.removeClass(active);
		});
	});
})();
