(function() {
	const btn    = $('[data-toggle="menu"]');
	const menu   = $('.main__topo');
	const active = 'main__topo--shown';

	btn.click(function(e) {
		e.preventDefault();

		menu.addClass(active);

		backdrop(function() {
			menu.removeClass(active);
		});
	});
})();
