(function() {
	var btn = $('.categorias > .btn');
	var todasCategorias = $('.categorias-todas');
	var expanded = false;

	btn.click(function() {

		expanded = !expanded;

		btn.attr('aria-expanded', expanded);

		if(midiaSize == 'xl' || midiaSize == 'lg') {
			btn.toggleClass('active');
			todasCategorias.toggleClass('show');
		}

		else if (midiaSize == 'md' || midiaSize == 'sm' || midiaSize == 'xs') {
			todasCategorias.collapse('toggle');
		}
	});
})();
