function backdrop (action) {
	let bck = document.createElement('div');

	$(bck).click(function(e) {
		action();

		$('.backdrop').fadeOut(600, function() {
			$(this).remove();
		});
	})

	$(bck).addClass('backdrop');
	$('#app').append(bck);
}
